.SlidePaneOverlay {
  background-color: rgba(235, 235, 235, 0.3) !important;
  z-index: 999;
}

.SlidePane {
  box-shadow: 0px 16px 64px #D9D8D5;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

.SlidePane .slide-pane__content {
  padding: 0;
  height: 100%;
}

@media screen and (max-width: 600px) {
  .SlidePane {
    width: 100% !important;
    height: 100%;
  }
}